import React, {useEffect} from 'react';
import './index.css';
import Card from "../../components/Card/index.js";
import {useNavigate} from "react-router-dom";

const AboutPage = () => {
    useEffect(()=>{
        // 模拟 302 重定向
        const shouldRedirect = true;
        if (shouldRedirect) {
          window.location.href = 'http://www.hysound.com/';  // 跳转到新网页
        }
    },[])
    const navigator = useNavigate();
    return (
        <>
            <div className="AboutPage desktop">
                <img src="/assets/about/c1.png" className='banner1'/>
                <div className='banner2'>
                    <img src="/assets/about/c2.png" className='banner2-left'/>
                    <div className='description'>
                        <div className='text'>
                            AudioNova傲诺瓦致力于让所有听损人士回归品质生活，同时为您提供从听力早筛、助听器及听力康复等一站式的专业服务和解决方案。
                        </div>
                        <div className='title'>
                            构造一个所有人<br/>
                            都可以享受聆听的<br/>
                            快乐世界，<br/>
                            <span>实现人生所想。</span>
                        </div>

                    </div>
                </div>
                <img src="/assets/about/c3.png" className='banner3'/>
                <div className='banner4'>
                    <div className='description'>
                        <div className='text'>
                            在中国，我们依托强大的技术背景与专家阵容，帮助无数个家庭实现了重获听力的梦想。
                            <br/>
                            在这不断进步与探索的道路当中，我们一直恪守诺言，牢记使命，构造一个所有人都可以享受聆听的快乐世界。
                        </div>
                    </div>
                    <img src="/assets/about/c4.png" className='banner4-left'/>
                </div>
                <div className='banner5'>
                    <img src="/assets/about/c5.png" className='banner5-left'/>
                    <div className='description'>
                        <div className='title'>
                            我们是您身边的
                            <br/>
                            <span>听力守护专家。</span>
                        </div>
                    </div>
                </div>
                {/* <div className="card-container">
                    <Card width={650} height={200} title='查询您附近的门店' other='立即探索' bgImg='assets/about/c6.png' color="#FFF" titleSize={36} url='/store-query'/>
                    <Card width={650} height={200} title='我们的服务' other='立即探索' bgImg='assets/about/c7.png' color="#FFF" titleSize={36} url='/service'/>
                </div> */}
            </div>
            <div className="AboutPage mobile">
                <img src="/assets/about/mobile-c1.png" className='banner1'/>
                <div className="card-container">
                    <img src="/assets/about/mobile-c2.png" alt="" onClick={()=>{
                        navigator('/store-query')
                    }}/>
                    <img src="/assets/about/mobile-c3.png" alt="" onClick={()=>{
                        navigator('/service')
                    }}/>
                </div>
            </div>

        </>
    )
}
export default AboutPage;